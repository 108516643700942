<template lang="pug">
div
  om-table(
    :items="accounts"
    :columns="columns"
    :allItemsCount="accountCount"
    :selectable="false"
    :pagination="pagination"
    :sorting="sorting"
    @sortingChange="sorting = $event"
    @paginationChange="pagination = $event"
    :columnClasses="columnClasses"
  )
    template(slot="name" slot-scope="{ rowData }")
      span.brand-sub-account-name.brand-link(
        :title="rowData.row.name"
        @click="logInto(rowData.row)"
      ) {{ rowData.row.name }}
    template(slot="users" slot-scope="{ rowData }")
      popper(
        trigger="click"
        :options="{ placement: 'top' }"
        transition="fade"
        enter-active-class="fade-enter-active"
        leave-active-class="fade-leave-active"
        @created="usersPopoverCreated($event, rowData.row._id)"
      )
        .popper.brand-users-popover
          .brand-users-header
            div {{ $t('users') }}
            .brand-users-settings
              span.oi.oi-cog.cursor-pointer(
                @click="showSubAccountUserModal(rowData.row)"
                title="settings"
                aria-hidden="true"
              )
          user-rows(
            :users="users"
            :subAccountId="rowData.row._id"
            :account="account"
            @refreshSubAccounts="fetchSubAccounts"
          )
        span.cursor-pointer(slot="reference")
          span.brand-link {{ rowData.row[rowData.column.key] | thousandSep }}
    template(slot="limits.maxVisitor" slot-scope="{ rowData }")
      span.brand-link(@click="showSubAccountVisitorLimitModal(rowData.row)")
        span(v-if="rowData.row.limits.maxVisitor === null") {{ $t('noLimit') }}
        span(v-else) {{ rowData.row.limits.maxVisitor | thousandSep }}
    template(slot="limits.usedVisitor" slot-scope="{ rowData }")
      .d-flex.flex-row.align-items-center
        .progress.flex-grow-1.mx-3
          .progress-bar(
            role="progressbar"
            :style="{ width: visitorPercent(rowData.row.limits.usedVisitor, rowData.row.limits.maxVisitor) + '%' }"
            :aria-valuenow="visitorPercent(rowData.row.limits.usedVisitor, rowData.row.limits.maxVisitor)"
            aria-valuemin="0"
            aria-valuemax="100"
          )
        .flex-column {{ rowData.row.limits.usedVisitor | thousandSep }}
    template(slot="options" slot-scope="{ rowData }")
      popper(trigger="click" :options="{ placement: 'left' }" @created="popoverInstance = $event")
        .popper.brand-settings-popover
          .setting-link(@click="showSubAccountRenameModal(rowData.row)") {{ $t('rename') }}
          .setting-link(@click="showSubAccountUserModal(rowData.row)") {{ $t('manageUsers') }}
          .setting-link(@click="showSubAccountVisitorLimitModal(rowData.row)") {{ $t('setVisitorLimit') }}
          // router-link(to="/" tag="div" class="setting-link") Duplicate
          .setting-link(@click="removeSubAccount(rowData.row)") {{ $t('delete') }}
        span(slot="reference")
          span.oi.oi-cog.brand-settings-icon.cursor-pointer(title="settings" aria-hidden="true")

  sub-account-rename-modal(@refreshSubAccounts="fetchSubAccounts")
  sub-account-user-modal(@refreshSubAccounts="fetchSubAccounts")
  sub-account-visitor-limit-modal(@updateSubAccountLimit="updateSubAccountLimit")
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import REMOVE_SUBACCOUNT from '@/graphql/RemoveSubAccount.gql';
  import GET_USERS from '@/graphql/GetUsers.gql';
  import GET_INVITATIONS from '@/graphql/GetInvitations.gql';
  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import GET_SUBACCOUNTS from '@/graphql/GetSubAccounts.gql';

  import SubAccountUserModal from '@/components/Modals/SubAccountUser.vue';
  import SubAccountRenameModal from '@/components/Modals/SubAccountRename.vue';
  import SubAccountVisitorLimitModal from '@/components/Modals/SubAccountVisitorLimit.vue';
  import UserRows from '@/components/UserRows.vue';

  import agencyDashboardMixin from '@/mixins/agencyDashboard';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import { getHeap } from '@/services/xray';
  import { setAccountIdInCookie } from '../../util';

  export default {
    components: {
      SubAccountUserModal,
      SubAccountRenameModal,
      SubAccountVisitorLimitModal,
      UserRows,
    },

    mixins: [agencyDashboardMixin],

    props: {
      account: { type: Object, required: true },
      filter: { type: Object, required: false },
    },

    data() {
      return {
        columnClasses: {
          name: 'text-left',
          options: 'flex-grow-0 text-center px-5 sub-account-setting-col',
        },
        subAccountData: {},
        popoverInstance: null, // the visible popper popover instance
        users: [],
        invitations: [],
        sorting: { field: 'name', direction: 1 },
        pagination: { page: 1, limit: 30 },
      };
    },

    computed: {
      ...mapGetters(['accountFeatures']),
      accounts() {
        return this.subAccountData?.subAccounts ?? [];
      },

      accountCount() {
        return this.subAccountData.count;
      },

      agencyLimits() {
        return this.account.limits;
      },

      agencyAssignable() {
        return this.agencyLimits.maxVisitor - this.account.totalAssigned;
      },

      columns() {
        return [
          { header: this.$t('subAccountName'), key: 'name' },
          { header: this.$t('users'), key: 'users' },
          { header: this.$t('campaigns'), key: 'campaignInfos.campaignCount' },
          { header: this.$t('conversions'), key: 'campaignInfos.totalConversions' },
          { header: this.$t('visitorLimit'), key: 'limits.maxVisitor' },
          { header: this.$t('visitors'), key: 'limits.usedVisitor' },
          { header: '', key: 'options' },
        ];
      },
    },

    watch: {
      filter: {
        handler() {
          this.pagination.page = 1;
          this.$store.commit('updateTablePagination', this.pagination);
          this.fetchSubAccounts();
        },
        deep: true,
      },
      sorting: {
        handler() {
          this.fetchSubAccounts();
        },
        deep: true,
      },
      pagination: {
        handler() {
          this.fetchSubAccounts();
        },
        deep: true,
      },
    },
    created() {
      this.fetchSubAccounts();
      this.$bus.$on('refreshSubAccounts', this.fetchSubAccounts);
      this.$bus.$on('updatedSubAccountCampaignStat', this.fetchSubAccounts);
    },
    beforeDestroy() {
      this.$bus.$off('refreshSubAccounts', this.fetchSubAccounts);
    },

    methods: {
      ...mapActions(['fetchAccount']),
      async logInto(row) {
        this.$store.commit('hideStickyBar', true);
        this.$router.replace({ name: 'account-switching' });

        const {
          data: { login },
        } = await this.$apollo.mutate({
          mutation: CREATE_LOGIN_CREDENTIALS,
          variables: { databaseId: row.databaseId },
        });
        const userId = login.dataAccountId;
        setAccountIdInCookie(userId);
        getHeap?.()?.identify?.(userId);
        if (login.redirectUrl) {
          window.location = login.redirectUrl;
        } else {
          this.$store
            .dispatch('login', {
              expiration: login.expiration,
              accountType: login.accountType,
              locale: login.locale,
              impersonate: true,
            })
            .then(() => {
              sessionStorage.removeItem('campaignFilter');
              this.$router.push({ name: login.redirectRoute, params: { userId } });
              this.$store.commit('hideStickyBar', false);
            });
        }
      },
      showSubAccountUserModal(row) {
        this.popoverInstance.doClose(); // hide the popover
        this.$modal.show('sub-account-user', { subAccountId: row._id, account: this.account });
      },
      showSubAccountRenameModal(row) {
        this.popoverInstance.doClose();
        this.$modal.show('sub-account-rename', { subAccountId: row._id, name: row.name });
      },
      showSubAccountVisitorLimitModal(row) {
        if (this.popoverInstance !== null) {
          this.popoverInstance.doClose();
        }
        this.$modal.show('sub-account-visitor-limit', {
          subAccountId: row._id,
          agencyLimits: this.agencyLimits,
          subAccountLimits: row.limits,
          totalAssigned: this.account.totalAssigned,
        });
      },
      usersPopoverCreated(event, subAccountId) {
        this.popoverInstance = event;
        this.$apollo
          .addSmartQuery('users', {
            query: GET_USERS,
            variables: { accountId: subAccountId },
          })
          .observer.subscribe(() => {
            if (this.popoverInstance.popperJS) {
              this.popoverInstance.popperJS.update();
            }
          });
        this.$apollo
          .addSmartQuery('invitations', {
            query: GET_INVITATIONS,
            variables: {
              accountId: subAccountId,
            },
          })
          .observer.subscribe(() => {
            if (this.popoverInstance.popperJS) {
              this.popoverInstance.popperJS.update();
            }
          });
      },
      removeSubAccount(row) {
        this.popoverInstance.doClose();

        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: REMOVE_SUBACCOUNT,
                    variables: {
                      accountId: row._id,
                    },
                  })
                  .then(() => {
                    this.$modal.hide('dialog');
                    this.fetchSubAccounts();
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },

      fetchSubAccounts() {
        this.$apollo
          .query({
            query: GET_SUBACCOUNTS,
            variables: {
              filter: this.filter,
              pagination: this.pagination,
              sorting: this.sorting,
            },
          })
          .then(({ data: { subAccountData } }) => {
            this.subAccountData = subAccountData;
          });
      },

      updateSubAccountLimit() {
        this.fetchSubAccounts();
        this.$emit('updateLimits');
      },
    },
  };
</script>

<style lang="sass">
  [data-modal="sub-account-user"]
    .form-control.is-invalid
      background: #ffe3e3
      &::placeholder
        color: #dc3545
        opacity: 1
      &:-ms-input-placeholder
        color: #dc3545
      &::-ms-input-placeholder
        color: #dc3545
    .brand-modal .brand-modal-header .brand-modal-title
      font-size: 1.25rem
      font-weight: bold
    .brand-modal .brand-modal-header .brand-modal-action-icon
      top: 1.5rem
  .progress
    border-radius: 3px
  .th.sub-account-setting-col
    padding-right: 3.5rem !important
</style>

<style lang="sass">
  .progress
    border-radius: 3px
  .th.sub-account-setting-col
    padding-right: 3.5rem !important
    .managed-accounts-table &
      padding-right: 2.5rem !important
</style>
